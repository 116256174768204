import { useEffect, useState } from 'react';
import { userApi } from '../../../apis/';

export const Post = () => {
  const [dataPost, setDataPost] = useState<any>({});
  const [listActive, setListActive] = useState<any>([]);
  // const [listEditChecked, setListEditChecked] = useState<any>([]);

  const getAllData = async () => {
    try {
      const res: any = await userApi.getProductById(2);
      if (res.status === 'success') {
        setDataPost(res.data);
        setListActive(res.data.img_labels);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkActive = (dataActive: any) => {
    const temp = listActive?.filter((item: any) => item === dataActive);
    return temp;
  };

  console.log('dataPost: ', dataPost);
  // console.log('listActive: ', listActive);

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <h2 className="title-post">MONCOW image</h2>
      <div className="wrap-post">
        <div className="post-image">
          <img src={dataPost?.img_link} alt="" />
        </div>
        <div className="post-content">
          {dataPost?.show_label?.map((post: any) => (
            <div key={post.category_id} className="post-content-block">
              <p>{`${post.category_text}:`}</p>
              <ul>
                {post.list_label?.map((label: any) => (
                  <li key={label.label_id}>
                    <input type="checkbox" defaultChecked={checkActive(label.label_id)?.length} name="" id="" />
                    <span>{label.label_text}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          {/* List Button */}
          <div className="list-btn-post">
            <div className="custom-btn">Update Label</div>
            <div className="custom-btn">Next Image</div>
          </div>
        </div>
      </div>
    </>
  );
};
