import { ChildrenProps } from '../types';
import { Header } from '../components/Header';
// import { Footer } from '../components/Footer';
import { Sidebar } from '../components/Sidebar';
import '../assets/styles/customer/style.scss';

const CustomerLayout = ({ children }: ChildrenProps) => {
  return (
    <div className="wrapper">
      <Header />
      <div className="content">
        <Sidebar />
        <div className="main-content">{children}</div>
      </div>
      {/* <Footer />; */}
    </div>
  );
};

export default CustomerLayout;
