import { Fragment, useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";
import { customerRoutes } from "./routes";
import "./App.css";
import Error from "./pages/error";
import config from "./config";

const App = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    if (
      location?.pathname !== config.routes.imageDetail &&
      location?.pathname !== config.routes.showImage
    ) {
      localStorage.removeItem("imgdt");
      localStorage.removeItem("pgacc");
    }
  });
  const customerRoute = customerRoutes.map((route: any) => {
    const Page = route.component;
    let Layout: any = DefaultLayout;

    if (route.layout) {
      Layout = route.layout;
    } else if (route.layout === null) {
      Layout = Fragment;
    }

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <Layout>
            <Page />
          </Layout>
        }
      />
    );
  });

  // console.log(customerRoute);

  return (
    <Routes>
      {/* page Customer */}
      {customerRoute}

      {/* Page Not Found */}
      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default App;
