import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { userApi } from '../../../apis';
import { Form, Input, Modal, notification } from 'antd';
import { CloseCircleOutlined, EditOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';

const ModalEdit = ({ cateId, refreshListInput, statusUpdate }: any) => {
  const [api, showPopup] = notification.useNotification();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataModal, setDataModal] = useState<any>({});
  const [listLabelEdit, setListLabelEdit] = useState<any>([]);
  const [customListLabelEdit, setCustomtListLabelEdit] = useState<any>([]);

  // console.log('customListLabelEdit: ', customListLabelEdit);

  // Alert
  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: 'topRight',
      duration: 3,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: 'topRight',
      duration: 3,
    });
  };

  // Modal
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleAddLabel = () => {
    setListLabelEdit([...listLabelEdit, { id: uuidv4() }]);
  };
  const handleEditCate = async (cateId: any) => {
    try {
      const res: any = await userApi.getCategoryById(cateId);
      if (res.status === 'success') {
        setDataModal(res.data);
        setListLabelEdit(res.data.list_label);

        const handeleDataListLabel: any = {};
        res.data.list_label.map((label: any) => (handeleDataListLabel[`${label.id}`] = label.name));
        setCustomtListLabelEdit({ ...handeleDataListLabel });

        setIsModalOpen(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateCate = async (dataUpdate: any) => {
    try {
      // Handle Delete Name Category In List Data
      delete dataUpdate.category_name;

      const res: any = await userApi.updateCategory({
        category_id: dataModal?.category_id,
        category_name: dataModal?.category_name,
        category_enable: true,
        list_label: dataUpdate,
      });

      if (res.status === 'success') {
        refreshListInput(!statusUpdate);
        alertSuccess('Update Success.');
        setIsModalOpen(false);
      }
    } catch (err) {
      console.log(err);
      alertFail('Update Fail!');
    }
  };
  const handleDeleteLabel = (idRow: string) => {
    const temp = listLabelEdit.filter((label: any) => label.id !== idRow);
    setListLabelEdit([...temp]);
  };

  return (
    <>
      {showPopup}
      <EditOutlined className="btn-edit-cate" onClick={() => handleEditCate(cateId)} />
      <Modal open={isModalOpen} footer={null}>
        <Form
          onFinish={handleUpdateCate}
          initialValues={{ category_name: dataModal?.category_name, ...customListLabelEdit }}
        >
          <div className="update-item--modal">
            <h3 className="update-modal--header">Edit Category and Label</h3>
            <div className="update-modal--content">
              <div className="modal-content--category">
                <div className="modal-category">
                  <div className="add-category">
                    <Form.Item name="category_name" style={{ margin: 0 }}>
                      <input type="text" className="add-category--input" placeholder="Enter Category Name" />
                    </Form.Item>
                  </div>
                  <button type="button" className="add-label--button" onClick={handleAddLabel}>
                    <PlusOutlined className="add-label--icon" />
                    <span>Add Label</span>
                  </button>
                </div>
                <ul className="modal-label--list">
                  {listLabelEdit?.map((label: any) => (
                    <li key={label.id} className="modal-label--item">
                      <div className="modal-label--input">
                        <Form.Item
                          // name={`label-${label.id}`}
                          name={label.id}
                          rules={[{ required: true, message: 'Please input label!' }]}
                          style={{ margin: 0 }}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                      <button
                        className="remove-label--button"
                        onClick={() => {
                          handleDeleteLabel(label.id);
                        }}
                      >
                        <CloseCircleOutlined className="remove-label--icon" />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="update-modal--action">
                <button type="button" className="update-action--cancel" onClick={handleCancel}>
                  Cancel
                </button>
                <button type="submit" className="update-action--upgrade">
                  <SyncOutlined className="update-action--icon" />
                  <span>Update</span>
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalEdit;
