import axiosClient from "../axiosClient";

export const userApi = {
  async getProductById(id: number) {
    const url = `label/?img_id=${id}`;
    return axiosClient.get(url);
  },
  async getAllCategory() {
    const url = "category/allData";
    return axiosClient.get(url);
  },
  async getListAccount(page: any) {
    const url = `img/accData?${page}`;
    return axiosClient.get(url);
  },
  async getListImage(params: any) {
    const url = `img/infoData?${params}`;
    return axiosClient.get(url);
  },
  async getNextImage(imageId: any) {
    const url = `img/nextByAcc?img_id=${imageId}`;
    return axiosClient.get(url);
  },
  async postLabelForImage(data: any) {
    const url = `img/setLabel`;
    return axiosClient.post(url, data);
  },
  async getCategoryById(id: any) {
    const url = `category/getById?cat_id=${id}`;
    return axiosClient.get(url);
  },
  async addCategory(data: any) {
    const url = `category/newCategory`;
    return axiosClient.post(url, data);
  },
  async updateCategory(data: any) {
    const url = `category/modify`;
    return axiosClient.post(url, data);
  },

  async crawlImage(data: any) {
    const url = `crawl`;
    return axiosClient.post(url, data);
  },
};
