import { CheckCircleFilled, CloseOutlined } from "@ant-design/icons";
import { Pagination } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { userApi } from "../../../apis";
import config from "../../../config";

export const ShowImage = () => {
  const navigate = useNavigate();
  const [listAccount, setListAccount] = useState<any>([]);
  const [listImage, setListImage] = useState<any>([]);
  const [accActive, setAccActive] = useState<any>();
  const pageAccount = JSON.parse(localStorage.getItem("pgacc") ?? "null") as
    | any
    | null;

  let [searchParams, setSearchParams]: any = useSearchParams({
    page: "1",
  });
  let [searchParamsAccount, setSearchParamsAccount]: any = useSearchParams({
    acc_txt: "",
    page: "1",
  });

  // HOOK EFFECT
  useEffect(() => {
    if (
      searchParamsAccount?.get("page") !== null &&
      searchParamsAccount?.get("acc_txt") !== ""
    ) {
      getListImage(searchParamsAccount);
    }
  }, [searchParamsAccount]);

  useEffect(() => {
    if (searchParams?.get("page") !== null) {
      if (pageAccount) {
        getListAccount(`page=${pageAccount}`);
      } else {
        getListAccount(searchParams);
      }
    }
  }, [searchParams, searchParamsAccount, pageAccount]);

  //GET LIST ACCOUNT
  const getListAccount = async (params: any) => {
    try {
      const res: any = await userApi.getListAccount(params);
      if (res?.status === "success") {
        setListAccount(res?.data);
        if (params.get("acc_txt") === null) {
          localStorage.setItem(
            "pgacc",
            JSON.stringify(params.get("page"))?.toString()
          );
        }
      }
    } catch (err) {}
  };

  //GET LIST IMAGE
  const getListImage = async (param: any) => {
    try {
      const res: any = await userApi.getListImage(param);
      if (res?.status === "success") {
        setListImage(res);
        setAccActive(res?.highlight_acc);
      }
    } catch (err) {}
  };

  //HANDLE IMAGE DETAIL
  const handleImageDetail = (
    src?: string,
    image_id?: number,
    list_label?: any
  ) => {
    const tmpImageDetail = {
      src: src,
      image_id: image_id,
      list_label: list_label,
    };
    localStorage.setItem("imgdt", JSON.stringify(tmpImageDetail)?.toString());
    navigate(config.routes.imageDetail);
  };

  //HANDLE PAGINATION ACCOUNT PAGE
  const handlePaginationPage = (page: any) => {
    if (searchParams) {
      setSearchParams({ page: page });
    }
    if (searchParams.get("acc_txt") === null) {
      localStorage.setItem(
        "pgacc",
        JSON.stringify(searchParams.get("page"))?.toString()
      );
    }
  };

  //HANDLE PAGINATION IMAGE OF ACCOUNT PAGE
  const handlePaginationPageImage = (page: any) => {
    if (searchParamsAccount) {
      setSearchParamsAccount({
        page,
        acc_txt: searchParamsAccount.get("acc_txt"),
      });
    }
  };

  return (
    <div>
      <div className="list-name">
        <h2 className="list-name--header">List Instagram Name</h2>
        <ul className="list-name--content">
          {listAccount.map((item: string, index: number) => (
            <li className="list-name--item" key={index}>
              <button
                className={`name-item--button ${
                  item === searchParamsAccount?.get("acc_txt") ?? accActive
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  setSearchParamsAccount({ page: 1, acc_txt: item });
                }}
              >
                {item}
              </button>
            </li>
          ))}
        </ul>
        <Pagination
          total={listAccount?.total_page ? listAccount?.total_page * 12 : 1}
          current={+pageAccount ?? Number(searchParams?.get("page"))}
          onChange={handlePaginationPage}
        />
      </div>

      <div className="list-image">
        <div className="list-image--header">List Image</div>
        {searchParamsAccount?.get("acc_txt") && (
          <div className="list-image--content">
            <table className="list-image--table">
              <thead>
                <tr className="image-table--row">
                  <th className="image-table--head">#</th>
                  <th className="image-table--head">Image</th>
                  <th className="image-table--head align-left">Product Name</th>
                  <th className="image-table--head">Date</th>
                  <th className="image-table--head">Tags Name</th>
                  <th className="image-table--head">Tags</th>
                </tr>
              </thead>
              <tbody>
                {listImage?.data?.map((item: any, index: number) => (
                  <tr className="image-table--row" key={index}>
                    <td className="image-table--item">{item?.image_id}</td>
                    <td className="image-table--item">
                      <div className="table-item--image">
                        <div className="table-image">
                          <img
                            src={item?.image_url}
                            alt=""
                            className="img-avt"
                            onClick={() =>
                              handleImageDetail(
                                item?.image_url,
                                item?.image_id,
                                item?.list_label
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td className="image-table--item align-left">
                      <span className="table-item--name">
                        {" "}
                        {item?.image_name}
                      </span>
                    </td>
                    <td className="image-table--item">{item?.date_post}</td>
                    <td className="image-table--item">
                      <ul className="tag-name--list">
                        {item?.list_label?.length ? (
                          <>
                            {item?.list_label?.map((label: any, index: any) => (
                              <li className="tag-name--item" key={index}>
                                <span className="tag-name--text has-tag">
                                  {label}
                                </span>
                              </li>
                            ))}
                          </>
                        ) : (
                          <div className="tag-name--notag">
                            <CloseOutlined style={{ color: "#DE3B40" }} />
                          </div>
                        )}
                      </ul>
                    </td>
                    <td className="image-table--item">
                      <div className="tag-status">
                        <CheckCircleFilled
                          style={
                            item?.list_label?.length !== 0
                              ? { color: "#62CD15", fontSize: "20px" }
                              : { color: "#DE3B40", fontSize: "20px" }
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <Pagination
          current={Number(searchParamsAccount?.get("page"))}
          total={listImage?.total_page ? listImage?.total_page * 10 : 1}
          onChange={handlePaginationPageImage}
          className={`pagination-list--image ${
            searchParamsAccount?.get("acc_txt") ? "" : " hidden"
          }`}
        />
      </div>
    </div>
  );
};
