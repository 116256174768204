import axios from 'axios';
import urlApi from '../helper/urlApi';

const axiosClient = axios.create({
  baseURL: urlApi,
  headers: {
    'content-type': 'application/json',
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    const newConfig = config;
    const token = JSON.parse(localStorage.getItem('access_token'));
    if (token && token !== 'undefined' && token !== 'null') {
      newConfig.headers.Authorization = `Bearer ${token}`;
    }
    return newConfig;
  },
  (error) => Promise.reject(error),
);

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data && response.data.data && response.data.data.rows) {
      return response.data.data.rows;
    }
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  async (errors) => {
    if (errors.response?.status === 401) {
    }
    return Promise.reject(errors);
  },
);
export default axiosClient;
