import { useEffect, useState } from 'react';
import { userApi } from '../../../apis';
import { CategoryItem, LabelCategory } from '../../../types';
import { ArrowRightOutlined, CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input, notification } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import ModalEdit from '../../../modules/customer/modalEdit';

export const Category = () => {
  const [form] = Form.useForm();
  const [api, showPopup] = notification.useNotification();
  const [listCate, setListCate] = useState<any>([]);
  const [listInputLabel, setListInputLabel] = useState<any>([]);
  const [statusUpdate, setStatusUpdate] = useState<boolean>(false);

  // Alert
  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: 'topRight',
      duration: 3,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: 'topRight',
      duration: 3,
    });
  };

  const handleAddLabel = () => {
    setListInputLabel([...listInputLabel, { idRow: uuidv4() }]);
  };
  const handleDeleteLabel = (idRow: string) => {
    const temp = listInputLabel.filter((label: any) => label.idRow !== idRow);
    setListInputLabel([...temp]);
  };
  const handleSubmit = async (valueForm: any) => {
    try {
      const category_name = valueForm.category_name;
      delete valueForm.category_name;
      const list_label = Object.values(valueForm);

      const res: any = await userApi.addCategory({
        category_name,
        list_label,
      });

      if (res.status === 'success') {
        form.resetFields();
        setListInputLabel([]);
        alertSuccess('Add Success.');
      } else if (res.status === 'fail') {
        alertFail(res.message);
      }
    } catch (err) {
      console.log(err);
      alertFail('Add Fail!');
    }
  };
  const getAllCategory = async () => {
    try {
      const res: any = await userApi.getAllCategory();
      if (res.status === 'success') {
        setListCate(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const refreshListInput = (status: any) => {
    setStatusUpdate(status);
  };

  useEffect(() => {
    getAllCategory();
    // eslint-disable-next-line
  }, [listInputLabel.length === 0, statusUpdate]);

  return (
    <>
      {showPopup}
      <div className="create-box">
        <h2 className="create-box--header">Create Category and Label</h2>
        <Form form={form} initialValues={{ category_name: '' }} onFinish={handleSubmit}>
          <div className="create-box--content">
            <div className="create-box--category">
              <div className="create-category">
                <div className="add-category">
                  <Form.Item
                    name="category_name"
                    rules={[{ required: true, message: 'Please input category!' }]}
                    style={{ margin: 0 }}
                  >
                    <input type="text" className="create-category--input" placeholder="Enter Category Name" />
                  </Form.Item>
                </div>
                <div className="add-label" onClick={() => handleAddLabel()}>
                  <PlusOutlined className="add-label--icon" />
                  <span>Add Label</span>
                </div>
              </div>
              <ul className="add-label--list">
                {listInputLabel?.map((item: any) => (
                  <Form.Item
                    key={item.idRow}
                    name={`label-${item.idRow}`}
                    rules={[{ required: true, message: 'Please input label!' }]}
                    style={{ margin: 0 }}
                  >
                    <li className="add-label--item">
                      <div className="add-label--input">
                        <Input />
                      </div>
                      <button className="remove-label" onClick={() => handleDeleteLabel(item.idRow)}>
                        <CloseCircleOutlined className="remove-label--icon" />
                      </button>
                    </li>
                  </Form.Item>
                ))}
              </ul>
            </div>
            <div className="add-label--submit">
              <button className="add-label--submitbtn">
                <ArrowRightOutlined className="submitbtn--icon" />
                <span>Submit</span>
              </button>
            </div>
          </div>
        </Form>
      </div>
      <div className="update-box">
        <h2 className="update-box--header">All Category and Label</h2>
        <form className="update-box--form">
          <ul className="update-form--list">
            {listCate?.map((cate: CategoryItem) => (
              <li key={cate.category_id} className="update-form--item">
                <div className="update-form--category">
                  <span className="update-item--text">
                    {cate.category_name}
                    <ModalEdit
                      cateId={cate.category_id}
                      refreshListInput={refreshListInput}
                      statusUpdate={statusUpdate}
                    />
                  </span>
                  <button type="button" className="update-item--edit">
                    <i className="fa-light fa-pen-line icon-edit"></i>
                  </button>
                </div>
                <ul className="update-form--label">
                  {cate.list_label?.map((item: LabelCategory) => (
                    <li key={item.id} className="update-label">
                      <Checkbox checked={true} style={{ fontSize: '18px' }}>
                        {item.name}
                      </Checkbox>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          {/* <div className="update-form--submit">
            <button className="update-form--submitbtn">
              <SyncOutlined className="update-icon" />
              <span className="update-form--submittext">Update</span>
            </button>
          </div> */}
        </form>
      </div>
    </>
  );
};
