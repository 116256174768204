import config from "../config";
import { Home, Category, Post } from "../pages";
import CustomerLayout from "../layouts/CustomerLayout";
import { ShowImage } from "../pages/customer/showImage";
import ImageDetail from "../pages/customer/showImage/imageDetail";

export const customerRoutes: any = [
  { path: config.routes.home, component: Home, layout: CustomerLayout },
  { path: config.routes.post, component: Post, layout: CustomerLayout },
  { path: config.routes.category, component: Category, layout: CustomerLayout },
  {
    path: config.routes.showImage,
    component: ShowImage,
    layout: CustomerLayout,
  },
  {
    path: config.routes.imageDetail,
    component: ImageDetail,
    layout: CustomerLayout,
  },
];
