import {
  CloudSyncOutlined,
  FileImageOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../../config";
const sidebarList: any = [
  {
    label: "Crawl images",
    key: "1",
    path: `${config.routes.home}`,
    pathActive: [config.routes.home],
    icon: <CloudSyncOutlined />,
    active: false,
  },
  {
    label: "Category and Label",
    key: "2",
    path: `${config.routes.category}`,
    pathActive: [config.routes.category],
    icon: <UnorderedListOutlined />,
    active: false,
  },
  {
    label: "Show images",
    key: "3",
    path: `${config.routes.showImage}`,
    pathActive: [config.routes.showImage, config.routes.imageDetail],
    icon: <FileImageOutlined />,
    active: false,
  },
  {
    label: "Settings",
    key: "4",
    path: ``,
    pathActive: [],
    icon: <SettingOutlined />,
    active: false,
  },
];

export function Sidebar() {
  const [sideBarItems, setSidebarItems]: any = useState<any[]>(sidebarList);
  const location: any = useLocation();
  const navigate = useNavigate();

  // HOOK EFFECT
  useEffect((): void => {
    const tmpSidebarList = sideBarItems?.map((sidebar: any): any => {
      if (
        sidebar.pathActive.some(
          (pathActiveItem: any) => pathActiveItem === location.pathname
        )
      ) {
        return {
          ...sidebar,
          active: true,
        };
      }
      return {
        ...sidebar,
        active: false,
      };
    });
    setSidebarItems(tmpSidebarList);
  }, [location]);

  const onClickSidebar = (sidebarItem: any): void => {
    const tmpSidebarList = sideBarItems?.map((sidebar: any): any => {
      if (sidebarItem?.key === sidebar?.key) {
        return {
          ...sidebar,
          active: true,
        };
      }
      return {
        ...sidebar,
        active: false,
      };
    });

    setSidebarItems(tmpSidebarList);
    navigate(sidebarItem?.path);
  };

  return (
    <div className="sidebar">
      <ul className="sidebar-container">
        {sideBarItems?.map((item: any) => {
          return (
            <li
              key={item?.key}
              onClick={() => onClickSidebar(item)}
              className={`sidebar-item${item?.active ? " active" : ""}`}
            >
              {item?.icon}
              <span className="sidebar-item--name">{item?.label}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
