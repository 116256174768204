import Logo from '../../assets/images/logo.jpg';
import ImageAvart from '../../assets/images/image_avt.jpg';
import { BellOutlined, DownOutlined, MessageOutlined, QuestionOutlined, SearchOutlined } from '@ant-design/icons';

export function Header() {
  return (
    <div className="header">
      <div className="header-container">
        <div className="header-logo">
          <a href="#" className="header-logo--image">
            <img src={Logo} alt="" className="img-logo" />
          </a>
          <span className="header-logo--text">Visual Data Finder</span>
        </div>
        <div className="header-menu">
          <div className="header-menu--action">
            <button className="header-action--search">
              <SearchOutlined className="header-action--icon" />
            </button>
            <a href="#" className="header-action--help">
              <QuestionOutlined className="header-action--icon" />
            </a>
            <button className="header-action--chat">
              <MessageOutlined className="header-action--icon" />
            </button>
            <button className="header-action--bell">
              <BellOutlined className="header-action--icon" />
            </button>
          </div>
          <div className="header-menu--line"></div>
          <div className="header-menu--profile">
            <div className="header-profile--avt">
              <img src={ImageAvart} alt="" className="img-avt" />
            </div>
            <div className="header-profile--action">
              <span className="header-profile--name">Admin</span>
              <DownOutlined style={{ fontSize: '12px' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
