import './error.scss';

export default function Error() {
  return (
    <div className="page_404">
      404
      <br />
      Page not found
    </div>
  );
}
