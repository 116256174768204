import { useState } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Form, Pagination, notification } from 'antd';
import { userApi } from '../../../apis';
import overLoader from '../../../components/overLoader';

export const Home = () => {
  const [api, showPopup] = notification.useNotification();
  const [inputCrawl, setInputCrawl] = useState<string>('');
  const [listImageCrawl, setListImageCrawl] = useState<any>([]);
  const [statusCrawl, setStatusCrawl] = useState<boolean>(false);

  // Alert
  // const alertSuccess = (message: string) => {
  //   api.success({
  //     message,
  //     placement: 'topRight',
  //     duration: 3,
  //   });
  // };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: 'topRight',
      duration: 3,
    });
  };

  const handleSubmit = async (dataSubmit: any) => {
    setInputCrawl(dataSubmit.account_hastag);
    setStatusCrawl(true);
    try {
      const res: any = await userApi.crawlImage({
        accname: dataSubmit.account_hastag,
        numimg: Number(dataSubmit.number_of_image),
        numimgShow: 10,
      });
      if (res.status === 'success') {
        console.log('dataSubmit: ', res);
        setStatusCrawl(false);
        setListImageCrawl(res.data);
      } else if (res.status === 'fail') {
        setStatusCrawl(false);
        alertFail(res.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNextPage = (page: any) => {
    console.log(page);
  };

  return (
    <>
      {statusCrawl ? overLoader() : ''}
      <Form onFinish={handleSubmit} initialValues={{ account_hastag: '', number_of_image: '' }}>
        <div className="crawl-form">
          <h2 className="crawl-form--header">Crawl Image</h2>
          <div className="crawl-form--content">
            <div className="crawl-form--input">
              <Form.Item
                name="account_hastag"
                rules={[{ required: true, message: 'Please input account or hashtag!' }]}
                style={{ margin: 0 }}
              >
                <input type="text" className="crawl-input--field" placeholder="Choose account or hashtag" />
              </Form.Item>
            </div>
            <div className="crawl-form--input">
              <Form.Item
                name="number_of_image"
                rules={[{ required: true, message: 'Please input num of images!' }]}
                style={{ margin: 0 }}
              >
                <input type="text" className="crawl-input--field" placeholder="Num of images" />
              </Form.Item>
            </div>
            <div className="crawl-form--submit">
              <button className="crawl-submit--button">
                <ArrowRightOutlined className="fa-light fa-arrow-right" />
                <span className="crawl-button--text">Submit</span>
              </button>
            </div>
          </div>
        </div>
      </Form>
      <div className="crawl-image">
        <h2 className="crawl-image--header">
          Images of
          <span className="crawl-header--hashtag" style={{ marginLeft: 10 }}>
            {inputCrawl ? inputCrawl : '...'}
          </span>
        </h2>
        <div className="crawl-image--content">
          <ul className="crawl-image--list">
            {listImageCrawl?.image_list?.map((image: string) => (
              <li className="crawl-image--item">
                <img src={image} alt="" className="img-crawl" />
              </li>
            ))}
          </ul>
        </div>
        {/* Pagination */}
        {/* <ul className="crawl-image--pagination">
          <li className="crawl-pagination--item">
            <button className="pagination-item--control">
              <i className="fa-light fa-chevron-left pagination-item--icon"></i>
            </button>
          </li>
          <li className="crawl-pagination--item">
            <button className="pagination-item--number active">1</button>
          </li>
          <li className="crawl-pagination--item">
            <button className="pagination-item--number">2</button>
          </li>
          <li className="crawl-pagination--item">
            <button className="pagination-item--number">3</button>
          </li>
          <li className="crawl-pagination--item">
            <button className="pagination-item--number">4</button>
          </li>
          <li className="crawl-pagination--item">
            <button className="pagination-item--number">...</button>
          </li>
          <li className="crawl-pagination--item">
            <button className="pagination-item--number">10</button>
          </li>
          <li className="crawl-pagination--item">
            <button className="pagination-item--number">11</button>
          </li>
          <li className="crawl-pagination--item">
            <button className="pagination-item--control">
              <i className="fa-light fa-chevron-right pagination-item--icon"></i>
            </button>
          </li>
        </ul> */}
        <Pagination defaultCurrent={1} total={listImageCrawl.total_page} onChange={handleNextPage} />
      </div>
    </>
  );
};
