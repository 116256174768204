import { useEffect, useState } from "react";
import { userApi } from "../../../../apis";
import {
  CheckOutlined,
  ForwardOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { notification } from "antd";

const ImageDetail = () => {
  const [statusNextImage, setStatusNextImage] = useState<any>(false);
  const [imageSrc, setImageSrc] = useState<string | undefined>("");
  const [listLabelByImage, setListLabelByImage] = useState<any>([]);
  const [listLabel, setListLabel] = useState<any>([]);
  const [api, showPopup] = notification.useNotification();

  const imageDetailData = JSON.parse(
    localStorage.getItem("imgdt") ?? "null"
  ) as any | null;

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 3,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  // HOOK EFFECT
  useEffect(() => {
    if (imageDetailData) {
      setImageSrc(imageDetailData?.src);
    } else {
      setImageSrc("");
    }
  }, []);

  useEffect(() => {
    getListLabelByImage(imageDetailData?.image_id);
  }, []);

  //HANDLE NEXT IMAGE
  const handleNextImage = async (image_id: any) => {
    try {
      const res: any = await userApi.getNextImage(image_id);
      if (res?.status === "success") {
        const tmpImageDetail = {
          src: res?.img_link,
          image_id: res?.img_id,
          list_label: res?.img_labels,
        };
        localStorage.setItem("imgdt", JSON.stringify(tmpImageDetail));
        setImageSrc(tmpImageDetail.src);
        getListLabelByImage(res?.img_id);
      }
      if (res.status === "end") {
        setStatusNextImage(true);
      }
    } catch (err) {}
  };

  //  GET LIST LABEL
  const getListLabelByImage = async (image_id: any) => {
    try {
      const res: any = await userApi.getProductById(image_id);
      if (res.status === "success") {
        setListLabelByImage(res.data);
        setListLabel(res?.data?.img_labels);
      }
    } catch (err) {}
  };

  //UPDATE LABEL
  const handleUpdateLabel = async () => {
    if (listLabel) {
      try {
        const res: any = await userApi.postLabelForImage({
          image_id: `${imageDetailData?.image_id}`,
          list_label: listLabel?.map(String),
        });
        if (res?.status === "success") {
          const tmpUpdatedImgLabel = {
            ...imageDetailData,
            list_label: listLabel,
          };
          localStorage.setItem("imgdt", JSON.stringify(tmpUpdatedImgLabel));
          alertSuccess("Update Success.");
          handleNextImage(imageDetailData?.image_id);
        } else {
          alertFail("Update Fail.");
        }
      } catch (err) {}
    }
    return "";
  };

  //HANDLE ONCHANGE CHECKBOX
  const handleOnChange = (label_id: any) => {
    if (listLabel.includes(label_id)) {
      const updatedList = listLabel.filter((item: any) => item !== label_id);
      setListLabel(updatedList);
    } else {
      setListLabel([...listLabel, label_id]);
    }
  };

  return (
    <>
      {showPopup}
      <div className="product-info">
        <h2 className="product-info--header">Product Info</h2>
        <div className="product-info--content">
          <div className="product-content--image">
            <img src={imageSrc} alt="" className="img-product" />
          </div>
          <div className="product-content--detail">
            <h3 className="product-detail--title">
              {listLabelByImage?.img_owner}
            </h3>
            <p className="product-detail--description">
              {listLabelByImage?.img_caption}
            </p>
          </div>
        </div>
      </div>
      <div className="product-update">
        <h2 className="product-update--header">Update Label</h2>
        <div className="product-update--form">
          <ul className="update-form--list">
            {listLabelByImage?.show_label?.map((item: any, index: any) => (
              <li className="update-form--item" key={index}>
                <div className="update-form--category">
                  <h2 className="update-item--text">{item?.category_text}</h2>
                </div>
                <ul className="update-form--label">
                  {item?.list_label?.map((label: any, index: any) => (
                    <li className="update-label" key={`label-${index}`}>
                      <label className="custom-checkbox">
                        <span className="custom-checkbox--label">
                          {label?.label_text}
                        </span>
                        <input
                          type="checkbox"
                          className="label-checkbox"
                          value={label?.label_id}
                          // defaultChecked={
                          //   !!listLabelByImage?.img_labels?.includes(
                          //     label?.label_id
                          //   )
                          //     ? true
                          //     : false
                          // }
                          checked={listLabel.includes(label?.label_id)}
                          onChange={() => handleOnChange(label?.label_id)}
                          // >
                        />
                        <span className="checkmark">
                          <CheckOutlined
                            className="icon-checkbox"
                            style={{ fontSize: "12px" }}
                          />
                        </span>
                      </label>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <div className="update-form--action">
            <button
              className="update-action--update  update-btn"
              onClick={handleUpdateLabel}
            >
              <i className="fa-light fa-rotate"></i>
              <span className="update-form--textbtn">
                <SyncOutlined />
                {" Update Label"}
              </span>
            </button>
            <button
              className={`update-action--next next-btn${
                statusNextImage ? " disable" : ""
              }`}
              onClick={() => handleNextImage(imageDetailData?.image_id)}
              disabled={statusNextImage}
            >
              <i className="fa-light fa-forward"></i>
              <span className="update-form--textbtn ">
                <ForwardOutlined style={{ color: "#fff" }} />
                {" Next Image"}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default ImageDetail;
